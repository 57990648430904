/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';

// import { Main } from 'styles/write-for-us';
import 'styles/write-for-us.scss';
const BlogIndex = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="ruttl Blog | Write for us ✍️"
          description="Loved ruttl’s blog and would like to become a contributor? Check out our guest posting program and write a ruttl blog today!"
          url="https://ruttl.com/blog/write-for-us/"
        />
        <main className="write-styled-main">
          <div className="write-for-us">
            <div className="container">
              <img
                src="/assets/img/write-for-us-blog.jpg"
                alt="Write for us"
                className="write-for-us-banner"
              />
              <h1>Write for ruttl</h1>
              <p>Thanks for showing interest in writing for ruttl’s blog!</p>
              <p>
                We believe in the power of collaboration and welcoming people
                from all backgrounds, whether you&apos;re a writer, expert, or
                just passionate about our topics. Let&apos;s team up to inspire,
                educate, and entertain our audience with your unique
                perspective.
              </p>

              <h4>Blog Content:</h4>
              <ul>
                <li>
                  Our blog covers various topics: UI/UX, Web Design, Web
                  Development, Project Management, Business Insights, and Useful
                  Tools for Design and Development.
                </li>
                <li>
                  Keep the word count between 1000-1500 words for your guest
                  post.
                </li>
                <li>
                  If you use statistics, provide a link to the original data
                  source.
                </li>
              </ul>
              <h4>Content Sharing:</h4>
              <ul>
                <li>
                  Use Google Docs exclusively when sharing your written content
                  with us.
                </li>
                <li>
                  Maintain a consistent structure by using H2 and H3 headers
                  throughout your post.
                </li>
                <li>
                  Include appropriate image attribution, specifying the image
                  source and its licensing (Creative Commons or from a stock
                  photo website). The final decision to include them will rest
                  with us.
                </li>
              </ul>

              <h4>Alignment with Brand Voice:</h4>
              <ul>
                <li>
                  Familiarize yourself with our brand&apos;s voice and tone by
                  reviewing our past blog posts.
                </li>
                <li>
                  Ensure your guest post&apos;s style aligns with our
                  established brand identity.
                </li>
              </ul>
              <h4>Writing Practices:</h4>
              <ul>
                <li>
                  Follow writing best practices: no plagiarism, use concise
                  sentences, and create smaller paragraphs for better
                  readability.
                </li>
              </ul>
              <h4>Author Bio and Link:</h4>
              <ul>
                <li>
                  You can include one outbound hyperlink in your author bio.
                </li>
                <li>
                  This link can direct readers to your website or a preferred
                  social media page.
                </li>
                <li>
                  Make the most of this opportunity to connect with our
                  audience.
                </li>
              </ul>
              <p>
                By structuring your content in line with these guidelines,
                you&apos;ll contribute to our blog&apos;s consistency and
                maintain the quality our readers expect.
              </p>
              <h3>Next steps</h3>
              <p>
                Do you feel that you are a good fit and would love to become a
                contributor? To get started, simply send us an email at&nbsp;
                <a href="mailto:marketing@ruttl.com">
                  marketing@ruttl.com
                </a>{' '}
                with the subject “Your Name - ruttl Guest Blog Collaboration”.
                Inside your mail, include the following -{' '}
              </p>
              <ol>
                <li>Your name</li>
                <li>
                  Three samples of previous writing work (Self-Published
                  articles will work as well)
                </li>
                <li>Your bio in 3 sentences</li>
                <li>Your website or social media handles (if any)</li>
              </ol>
            </div>
          </div>
          <GetStartedCenter toggleSignup={toggleSignup} />
        </main>
      </>
    )}
  </Layout>
);

BlogIndex.propTypes = {
  location: PropTypes.object,
};

export default BlogIndex;
